import "plugins/index.js";
import "@/global";
import "./style/main.css";
import "@/plugins/";

import Vue from "vue";

import { apolloClient } from "plugins/apollo";
import store from "./store/index.js";
import VueApollo from "vue-apollo";

import App from "@/App.vue";
import router from "@/router";

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  apolloProvider,
}).$mount("#app");
