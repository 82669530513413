<template>
  <div class="vp-flex vp-border-b vp-px-4 vp-overflow-auto vp-flex-nowrap">
    <template v-for="(item, index) in items">
      <!-- Link -->
      <a
        v-if="item.href"
        :key="`item-${index}`"
        :href="item.href"
        target="_blank"
        :class="$options.style.mobileTab"
      >
        <VpIcon
          v-if="item.icon"
          :class="$options.style.mobileTabIcon"
          :name="item.icon"
        />
        <span>{{ item.label }}</span>
      </a>
      <!-- Internal Link -->
      <router-link
        :key="`item-router-${index}`"
        v-else-if="item.to"
        :to="item.to"
        custom
      >
        <template #default="{ href, navigate, isExactActive }">
          <a
            :class="{
              'vp-relative ': isExactActive,
              [$options.style.mobileTab]: true,
            }"
            :href="href"
            @click="navigate"
          >
            <VpIcon
              v-if="item.icon"
              :class="$options.style.mobileTabIcon"
              :name="item.icon"
            />
            <span>{{ item.label }}</span>
            <span
              v-if="isExactActive"
              class="vp-absolute vp-left-2 vp-right-2 vp-h-1 vp-bg-primary-500 vp-bottom-0 vp-rounded-t-md"
            />
          </a>
        </template>
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  style: {
    mobileTab: "vp-h-12 vp-flex vp-items-center vp-px-4 vp-whitespace-nowrap",
    mobileTabIcon: "vp-w-5 vp-h-5  vp-mr-2",
  },
  props: {
    items: Array,
  },
};
</script>
