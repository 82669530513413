import Vue from "vue";
Vue.mixin({
  data() {
    return {
      PLATFORM: "MOBILE",
    };
  },
});
/**
 * Import Vue List
 */
import { init as initVueList } from "plugins/v-list";
initVueList();
