<template>
  <VueForm
    :fields="fields"
    id="true"
    :get="get"
    :save="save"
    :footer="false"
    #default="{ state, saveItem, isSaving }"
    feature="STORE_THEME"
    class="vp-p-6"
  >
    <VpPageHeader>
      <p>Customize the colors on your store & biz page.</p>
      <p>
        Select the primary shade of your brand and palette will be generated
        automatically.
      </p>
    </VpPageHeader>
    <div class="vp-space-y-4">
      <Palette
        v-for="color in colorConfigs.brand"
        ref="color"
        :key="color.key"
        :name="color.value"
        v-model="state.colors[color.key]"
      />
    </div>
    <VyButton
      class="button--primary button--solid button--lg button--rounded vp-w-full"
      label="Save"
      :loading="isSaving"
      @click.native="saveItem"
    />
  </VueForm>
</template>

<script>
import Palette from "components/src/palette.vue";
import { post } from "utils/port";

import getColors from "@/graph/theme/getColors.gql";
import upsertTheme from "@/graph/theme/updateTheme.gql";

export default {
  components: {
    Palette,
  },

  data() {
    return {
      colorConfigs: {
        brand: [
          {
            key: "primaryColor",
            value: "Primary Color",
          },
          {
            key: "secondaryColor",
            value: "Secondary Color",
          },
        ],
      },
      fields: ["colors"],
    };
  },

  methods: {
    get() {
      return this.$query(getColors)
        .then(({ data }) => {
          const colors = {
            primaryColor: data.storeTheme?.primaryColor,
            secondaryColor: data.storeTheme?.secondaryColor,
          };
          return {
            values: { colors },
            res: data,
          };
        })
        .catch((err) => {
          console.error(err);
          this.error();
          throw err;
        });
    },
    save(id, payload) {
      return this.$mutate(upsertTheme, {
        input: payload.colors,
      })
        .then(() => {
          const deepLinkParams = {
            type: "colors",
            message: "Theme colors are saved.",
          };
          post("success", deepLinkParams);
        })
        .catch((err) => {
          console.error(err);
          this.error();
          throw err;
        });
    },

    error() {
      post("error", {
        message: "Error saving colors, please try again!",
      });
    },
  },
};
</script>
