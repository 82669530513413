<template>
  <TabView>
    <template #tabs>
      <DocsLink #default="{ credits }">
        <Tabs
          :items="[
            {
              label: 'Add Credits',
              icon: $options.icons.Add,
              to: { name: 'credits' },
            },
            {
              label: 'History',
              icon: $options.icons.History,
              to: { name: 'credits-history' },
            },
            {
              label: 'Why Credits',
              icon: $options.icons.Help,
              href: credits,
            },
          ]"
        />
      </DocsLink>
    </template>
    <template #default>
      <RouterView />
    </template>
  </TabView>
</template>

<script>
import Tabs from "@/components/Tabs.vue";
import TabView from "@/components/TabView.vue";
import { History, Add, Help } from "icons/icons.js";
import DocsLink from "components/src/docs-link/main.vue";

export default {
  icons: {
    History,
    Add,
    Help,
  },

  components: {
    Tabs,
    TabView,
    DocsLink,
  },
};
</script>
