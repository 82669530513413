import Vue from "vue";
import Vuex from "vuex";

import subscription from "store/src/subscription.js";
import biz from "store/src/biz.js";
import user from "store/src/user.js";
import store from "store/src/store.js";
import hydrate from "store/src/hydrate.js";

Vue.use(Vuex);

const storeInstance = new Vuex.Store({
  modules: {
    hydrate,
    subscription,
    store,
    biz,
    user,
  },
});

// Initial state
const initialState = JSON.parse(JSON.stringify(storeInstance.state));
//Resets the store when user logs out
const resetStore = () => {
  storeInstance.replaceState(JSON.parse(JSON.stringify(initialState)));
};

export default storeInstance;
export { resetStore };
