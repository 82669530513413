import Vue from "vue";
import VueRouter from "vue-router";

import Auth from "@/views/Auth.vue";
import CreditsIndex from "@/views/credits/Index.vue";
import CreditsAdd from "@/views/credits/Add.vue";
import CreditsHistory from "@/views/credits/History.vue";
import ThemeColors from "@/views/theme/Colors.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    component: Auth,
    children: [
      {
        path: "credits",
        component: CreditsIndex,
        children: [
          {
            path: "",
            name: "credits",
            component: CreditsAdd,
          },
          {
            path: "history",
            name: "credits-history",
            component: CreditsHistory,
          },
        ],
      },
      {
        path: "settings/theme/colors",
        name: "Colors",
        component: ThemeColors,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

export default router;
