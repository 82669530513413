<template>
  <div v-if="error">
    <p>There was an error</p>
    <div>
      {{ error }}
    </div>
  </div>
  <Loader v-else-if="loading" />
  <RouterView v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import hydrateQuery from "@/graph/hydrate.gql";
import { post } from "utils/port";
import bus from "utils/bus.js";
import campaigns from "@/graph/campaigns.gql";
import { setToken, setCampaign } from "plugins/utils";
import { init as initSplit } from "plugins/split";
import { init as initSegment } from "plugins/segment";
import Loader from "@/components/Loader.vue";
import { version } from "../../package.json";

export default {
  data() {
    return {
      loading: true,
      error: false,
    };
  },

  components: {
    Loader,
  },

  async mounted() {
    /**
     * window.VepaarMobile: Object coming from mobile app and object key should be,
     * token
     * platform: ios/android
     */

    //Taking token from route for development purpose only.
    if (this.$route.query.token) {
      setToken(this.$route.query.token);
      this.startHydration();
    } else {
      if (window.VepaarMobile) {
        setToken(window.VepaarMobile.token);
        this.startHydration();
      } else {
        post("get-vepaar-mobile");
        // this.error = message;
      }
    }

    /**
     * On iOS We need to explicitly fetch the Vepaar Mobile
     */
    bus.$on("vepaar-mobile", (payload) => {
      window.VepaarMobile = payload;
      setToken(payload.token);
      this.startHydration();
    });
  },

  computed: {
    ...mapGetters({
      analytics: "analytics",
      isSubscriptionActive: "subscription/isActive",
    }),
  },

  methods: {
    ...mapActions(["hydrate"]),

    async startHydration() {
      //Get Campaign ID
      const campaignId = await this.$query(campaigns)
        .then(({ data }) => {
          return data.campaigns.data[0]?.id;
        })
        .catch((err) => {
          this.error = err;
          console.error(err);
          post("error", {
            error: err,
            message: "Campaign Identification Failed",
          });
        });

      setCampaign(campaignId);

      // Hydrate Store
      this.hydrate({
        request: this.$query(hydrateQuery, {
          campaignId: Number(campaignId),
        }),
        version: version,
      })
        .then(() => {
          this.vendorPlugins();
        })
        .catch((err) => {
          this.error = err;
          post("error", {
            error: err,
            message: "Hydration Failed",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    vendorPlugins() {
      //Split.io
      initSplit(this.analytics.userId);

      // Segment.io
      if (this.isSubscriptionActive) {
        // Segment has monthly tracked users limit.therefore sending data of paid users only. learn more at https://segment.com/pricing/
        initSegment(this.analytics);
      }
    },
  },
};
</script>
