import Vue from "vue";
import VueForm from "components/src/vue-form/index.vue";
import { registerComponents } from "utils/register-components";

/**
 * Global components from Local Folder
 */
Vue.component("VueForm", VueForm);

/**
 * Global components from packages
 */
import Icon from "components/src/icon.vue";
import PageHeader from "components/src/page-header.vue";

const components = [{ Icon }, { PageHeader }];
registerComponents(Vue, components);
