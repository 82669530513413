<template>
  <Root class="vepaar-mobile">
    <RouterView />
    <PortalTarget
      id="slideover-container"
      name="slideover-container"
      multiple
    />
  </Root>
</template>

<script>
import Root from "components/src/root.vue";

export default {
  components: {
    Root,
  },
};
</script>

<style>
.vepaar-mobile {
  min-height: 100vh;
}
</style>
